import React, { useState } from 'react';
import gameLookup from '../utils/gameLookup';
import { useStats } from '../context/StatsContext';
import data from '../data/games.json';

const GameStats = () => {
	const { setSelectedGame, selectedGame } = useStats();
	const game = data[selectedGame];
	const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

	const sortedData = game?.data
		? [...game.data].sort((a, b) => {
				if (sortConfig.key === null) {
					return 0;
				}
				const key = sortConfig.key;
				const direction = sortConfig.direction === 'asc' ? 1 : -1;
				if (a[key] < b[key]) {
					return -1 * direction;
				}
				if (a[key] > b[key]) {
					return 1 * direction;
				}
				return 0;
		  })
		: [];

	const requestSort = (key) => {
		let direction = 'asc';
		if (sortConfig.key === key && sortConfig.direction === 'asc') {
			direction = 'desc';
		}
		setSortConfig({ key, direction });
	};

	const getClassNamesFor = (key) => {
		if (!sortConfig.key || sortConfig.key !== key) {
			return '';
		}
		return sortConfig.direction === 'asc' ? 'ascending' : 'descending';
	};

	return (
		<div className='max-w-screen overflow-scroll game-stats bg-white p-6 rounded-lg shadow-lg mb-6'>
			<div className='mb-6'>
				<h2 className='text-2xl font-semibold mb-2'>View by Game</h2>
				<select
					className='p-2 border w-44 rounded border-gray-300'
					onChange={(e) => setSelectedGame(e.target.value)}
					value={selectedGame || ''}
				>
					<option value=''>Select Game</option>
					{data &&
						data.map((game, index) => (
							<option
								key={index}
								value={index}
							>
								{game?.date}
							</option>
						))}
				</select>
			</div>
			{game && selectedGame !== null && (
				<div>
					<h3 className='text-xl font-semibold mb-4'>Game Stats - {game?.date}</h3>
					<table className='whitespace-nowrap min-w-full bg-white'>
						<thead className='bg-gray-100 tracking-wider'>
							<tr>
								{[
									'LINEUP',
									'AB',
									'R',
									'H',
									'RBI',
									'BB',
									'SO',
									'SB',
									'CS',
									'HBP',
									'SF',
									'AVG',
									'OBP',
									'SLG',
									'OPS',
								].map((key) => (
									<th
										key={key}
										className='p-2 border-b cursor-pointer'
										onClick={() => requestSort(key)}
									>
										{key} <span className={getClassNamesFor(key)}></span>
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{sortedData.map((player, index) => (
								<tr key={index}>
									<td className='p-2 border-b text-center'>{player.LINEUP}</td>
									<td className='p-2 border-b text-center'>{player.AB || 0}</td>
									<td className='p-2 border-b text-center'>{player.R || 0}</td>
									<td className='p-2 border-b text-center'>{player.H || 0}</td>
									<td className='p-2 border-b text-center'>{player.RBI || 0}</td>
									<td className='p-2 border-b text-center'>{player.BB || 0}</td>
									<td className='p-2 border-b text-center'>{player.SO || 0}</td>
									<td className='p-2 border-b text-center'>{player.SB || 0}</td>
									<td className='p-2 border-b text-center'>{player.CS || 0}</td>
									<td className='p-2 border-b text-center'>{player.HBP || 0}</td>
									<td className='p-2 border-b text-center'>{player.SF || 0}</td>
									<td className='p-2 border-b text-center'>{player.AVG || 0}</td>
									<td className='p-2 border-b text-center'>{player.OBP || 0}</td>
									<td className='p-2 border-b text-center'>{player.SLG || 0}</td>
									<td className='p-2 border-b text-center'>{player.OPS || 0}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

export default GameStats;
