import React from 'react';
import { useStats } from '../context/StatsContext';

const TeamStats = ({}) => {
	const { teamTotals } = useStats();

	return (
		<div className='max-w-screen overflow-scroll team-stats bg-white p-6 rounded-lg shadow-lg mb-6'>
			<h2 className='text-2xl font-semibold mb-4'>Team Stats</h2>
			<table className='min-w-full bg-white'>
				<thead className='bg-gray-100 tracking-wider'>
					<tr>
						<th className='p-2 border-b'>G</th>
						<th className='p-2 border-b'>AB</th>
						<th className='p-2 border-b'>R</th>
						<th className='p-2 border-b'>H</th>
						<th className='p-2 border-b'>RBI</th>
						<th className='p-2 border-b'>BB</th>
						<th className='p-2 border-b'>SO</th>
						<th className='p-2 border-b'>SB</th>
						<th className='p-2 border-b'>CS</th>
						<th className='p-2 border-b'>HBP</th>
						<th className='p-2 border-b'>SF</th>
						<th className='p-2 border-b'>AVG</th>
						<th className='p-2 border-b'>OBP</th>
						<th className='p-2 border-b'>SLG</th>
						<th className='p-2 border-b'>OPS</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className='p-2 border-b text-center'>{teamTotals.G}</td>
						<td className='p-2 border-b text-center'>{teamTotals.AB}</td>
						<td className='p-2 border-b text-center'>{teamTotals.R}</td>
						<td className='p-2 border-b text-center'>{teamTotals.H}</td>
						<td className='p-2 border-b text-center'>{teamTotals.RBI}</td>
						<td className='p-2 border-b text-center'>{teamTotals.BB}</td>
						<td className='p-2 border-b text-center'>{teamTotals.SO}</td>
						<td className='p-2 border-b text-center'>{teamTotals.SB}</td>
						<td className='p-2 border-b text-center'>{teamTotals.CS}</td>
						<td className='p-2 border-b text-center'>{teamTotals.HBP}</td>
						<td className='p-2 border-b text-center'>{teamTotals.SF}</td>
						<td className='p-2 border-b text-center'>{teamTotals.AVG}</td>
						<td className='p-2 border-b text-center'>{teamTotals.OBP}</td>
						<td className='p-2 border-b text-center'>{teamTotals.SLG}</td>
						<td className='p-2 border-b text-center'>{teamTotals.OPS}</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};
export default TeamStats;
