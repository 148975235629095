import React, { useState, useEffect } from 'react';
import aggregateStats from '../utils/aggregateStats';
import { useStats } from '../context/StatsContext';

const RecentGamesStats = ({}) => {
	const [numberOfGames, setNumberOfGames] = useState(5);
	const [recentStats, setRecentStats] = useState({});
	const { data } = useStats();
	const games = data;
	useEffect(() => {
		const recentGames = games.slice(-numberOfGames);
		const aggregated = aggregateStats(recentGames);
		setRecentStats(aggregated);
	}, [numberOfGames, games]);

	return (
		<div className='recent-games-stats bg-white p-6 rounded-lg shadow-lg mb-6'>
			<h2 className='text-2xl font-semibold mb-4'>Last {numberOfGames} Games</h2>
			<div className='mb-4'>
				<select onChange={(e) => setNumberOfGames(parseInt(e.target.value))}>
					<option value=''>Select Number of Games</option>
					<option value='1'>Last Game</option>
					<option value='3'>Last 3 Games</option>
					<option value='5'>Last 5 Games</option>
				</select>
			</div>
			<table className='min-w-full bg-white'>
				<thead>
					<tr>
						<th className='p-2 border-b'>Player</th>
						<th className='p-2 border-b'>G</th>
						<th className='p-2 border-b'>AB</th>
						<th className='p-2 border-b'>R</th>
						<th className='p-2 border-b'>H</th>
						<th className='p-2 border-b'>RBI</th>
						<th className='p-2 border-b'>BB</th>
						<th className='p-2 border-b'>SO</th>
						<th className='p-2 border-b'>SB</th>
						<th className='p-2 border-b'>CS</th>
						<th className='p-2 border-b'>HBP</th>
						<th className='p-2 border-b'>SF</th>
						<th className='p-2 border-b'>AVG</th>
						<th className='p-2 border-b'>OBP</th>
						<th className='p-2 border-b'>SLG</th>
						<th className='p-2 border-b'>OPS</th>
					</tr>
				</thead>
				<tbody>
					{Object.keys(recentStats.individualTotals || {}).map((player) => {
						const stats = recentStats.individualTotals[player];
						return (
							<tr key={player}>
								<td className='p-2 border-b text-center'>{player}</td>
								<td className='p-2 border-b text-center'>{stats.G}</td>
								<td className='p-2 border-b text-center'>{stats.AB}</td>
								<td className='p-2 border-b text-center'>{stats.R}</td>
								<td className='p-2 border-b text-center'>{stats.H}</td>
								<td className='p-2 border-b text-center'>{stats.RBI}</td>
								<td className='p-2 border-b text-center'>{stats.BB}</td>
								<td className='p-2 border-b text-center'>{stats.SO}</td>
								<td className='p-2 border-b text-center'>{stats.SB}</td>
								<td className='p-2 border-b text-center'>{stats.CS}</td>
								<td className='p-2 border-b text-center'>{stats.HBP}</td>
								<td className='p-2 border-b text-center'>{stats.SF}</td>
								<td className='p-2 border-b text-center'>{stats.AVG}</td>
								<td className='p-2 border-b text-center'>{stats.OBP}</td>
								<td className='p-2 border-b text-center'>{stats.SLG}</td>
								<td className='p-2 border-b text-center'>{stats.OPS}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default RecentGamesStats;
