import React, { useState } from 'react';
import { useStats } from '../context/StatsContext';

const StatsTable = ({}) => {
	const { individualTotals } = useStats();
	const playerStats = individualTotals;

	const [sortConfig, setSortConfig] = useState({ key: 'Player', direction: 'ascending' });

	const sortedPlayerStats = React.useMemo(() => {
		const sortedStats = Object.keys(playerStats).map((player) => ({
			player,
			...playerStats[player],
		}));

		if (sortConfig !== null) {
			sortedStats.sort((a, b) => {
				if (a[sortConfig.key] < b[sortConfig.key]) {
					return sortConfig.direction === 'ascending' ? -1 : 1;
				}
				if (a[sortConfig.key] > b[sortConfig.key]) {
					return sortConfig.direction === 'ascending' ? 1 : -1;
				}
				return 0;
			});
		}

		return sortedStats;
	}, [playerStats, sortConfig]);

	const requestSort = (key) => {
		let direction = 'ascending';
		if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
			direction = 'descending';
		}
		setSortConfig({ key, direction });
	};

	const getClassNamesFor = (name) => {
		if (!sortConfig) {
			return;
		}
		return sortConfig.key === name ? sortConfig.direction : undefined;
	};

	const getSortArrow = (name) => {
		if (sortConfig.key === name) {
			return sortConfig.direction === 'ascending' ? '▲' : '▼';
		}
		return '';
	};

	return (
		<div className='max-w-screen overflow-scoll stats-table bg-white p-6 rounded-lg shadow-lg mb-6'>
			<h2 className='text-2xl font-semibold mb-4'>Individual Player Stats</h2>
			<table className='whitespace-nowrap min-w-full bg-white'>
				<thead>
					<tr>
						{[
							'Player',
							'G',
							'AB',
							'R',
							'H',
							'RBI',
							'BB',
							'SO',
							'SB',
							'CS',
							'HBP',
							'SF',
							'AVG',
							'OBP',
						].map((header) => (
							<th
								key={header}
								onClick={() => requestSort(header)}
								// make text wider tracking
								className={`bg-gray-100 hover:bg-gray-50  tracking-wider  p-2 border-b cursor-pointer ${getClassNamesFor(
									header
								)}`}
							>
								{header}
								{/*
								{getSortArrow(header)} */}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{sortedPlayerStats.map((player) => (
						<tr key={player.player}>
							<td className='p-2 border-b text-center'>
								{player.player} #{player['#']}
							</td>

							<td className='p-2 border-b text-center'>{player.G}</td>
							<td className='p-2 border-b text-center'>{player.AB}</td>
							<td className='p-2 border-b text-center'>{player.R}</td>
							<td className='p-2 border-b text-center'>{player.H}</td>
							<td className='p-2 border-b text-center'>{player.RBI}</td>
							<td className='p-2 border-b text-center'>{player.BB}</td>
							<td className='p-2 border-b text-center'>{player.SO}</td>
							<td className='p-2 border-b text-center'>{player.SB}</td>
							<td className='p-2 border-b text-center'>{player.CS}</td>
							<td className='p-2 border-b text-center'>{player.HBP}</td>
							<td className='p-2 border-b text-center'>{player.SF}</td>
							<td className='p-2 border-b text-center'>{player.AVG}</td>
							<td className='p-2 border-b text-center'>{player.OBP}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default StatsTable;
