import React, { createContext, useContext, useState, useEffect } from 'react';
import aggregateStats from '../utils/aggregateStats';
import data from '../data/games.json';
const StatsContext = createContext();
const StatsProvider = ({ children }) => {
	const [teamTotals, setTeamTotals] = useState({});
	const [individualTotals, setIndividualTotals] = useState({});
	const [selectedPlayer, setSelectedPlayer] = useState(null);
	const [selectedGame, setSelectedGame] = useState(null);
	const [player1, setPlayer1] = useState(null);
	const [player2, setPlayer2] = useState(null);

	useEffect(() => {
		const { individualTotals, teamTotals } = aggregateStats(data);
		setIndividualTotals(individualTotals);
		setTeamTotals(teamTotals);
	}, []);

	return (
		<StatsContext.Provider
			value={{
				teamTotals,
				individualTotals,
				selectedPlayer,
				setSelectedPlayer,
				selectedGame,
				setSelectedGame,
				player1,
				setPlayer1,
				player2,
				setPlayer2,
				data,
			}}
		>
			{children}
		</StatsContext.Provider>
	);
};
export const useStats = () => useContext(StatsContext);
export default StatsProvider;
