import React, { useEffect, useState } from 'react';
import aggregateStats from '../utils/aggregateStats';
import { useStats } from '../context/StatsContext';

const CustomGamesStats = () => {
	const [selectedGames, setSelectedGames] = useState([]);
	const [customStats, setCustomStats] = useState({});
	const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
	const { data } = useStats();
	const games = data;

	const toggleGameSelection = (gameDate) => {
		setSelectedGames((prev) =>
			prev.includes(gameDate) ? prev.filter((date) => date !== gameDate) : [...prev, gameDate]
		);
	};

	const calculateStats = () => {
		const selectedGamesData = games.filter((game) => selectedGames.includes(game.date));
		const aggregated = aggregateStats(selectedGamesData);
		setCustomStats(aggregated);
	};

	const requestSort = (key) => {
		let direction = 'asc';
		if (sortConfig.key === key && sortConfig.direction === 'asc') {
			direction = 'desc';
		}
		setSortConfig({ key, direction });
	};

	const getClassNamesFor = (key) => {
		if (!sortConfig.key || sortConfig.key !== key) {
			return '';
		}
		return sortConfig.direction === 'asc' ? 'ascending' : 'descending';
	};

	const sortedData = Object.keys(customStats.individualTotals || {})
		.map((player) => ({
			player,
			...customStats.individualTotals[player],
		}))
		.sort((a, b) => {
			if (sortConfig.key === null) {
				return 0;
			}
			const key = sortConfig.key;
			const direction = sortConfig.direction === 'asc' ? 1 : -1;
			if (a[key] < b[key]) {
				return -1 * direction;
			}
			if (a[key] > b[key]) {
				return 1 * direction;
			}
			return 0;
		});

	useEffect(() => {
		if (selectedGames.length > 0) calculateStats();
	}, [selectedGames]);
	return (
		<div className='custom-games-stats bg-white p-6 rounded-lg shadow-lg mb-6'>
			<div className='flex justify-between'>
				<h2 className='text-2xl font-semibold mb-4'>Multiple Game Stats</h2>
				<div>
					{selectedGames.length > 0 && (
						<button
							className=' bg-gray-600 text-white px-4  py-2 rounded'
							onClick={() => setSelectedGames([])}
						>
							Clear
						</button>
					)}
				</div>
			</div>
			<fieldset className='mb-4'>
				<legend className='text-base font-semibold leading-6 text-gray-900'>Select Games</legend>
				<div className='mt-4 divide-y divide-gray-200 border-b border-t border-gray-200 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6'>
					{games.map((game, index) => (
						<div
							key={index}
							className='relative flex items-start py-4'
						>
							<div className='mr-3 flex h-6 items-center'>
								<input
									id={`game-${index}`}
									name={`game-${index}`}
									type='checkbox'
									checked={selectedGames.includes(game.date)}
									onChange={() => toggleGameSelection(game.date)}
									className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
								/>
							</div>
							<div className='min-w-0  text-sm leading-6'>
								<label
									htmlFor={`game-${index}`}
									className='select-none font-medium text-gray-900'
								>
									{game.date}
								</label>
							</div>
						</div>
					))}
				</div>
			</fieldset>

			{sortedData.length > 0 && (
				<table className='min-w-full bg-white'>
					<thead className='bg-gray-100 tracking-wider p-2'>
						<tr>
							{[
								'player',
								'G',
								'AB',
								'R',
								'H',
								'RBI',
								'BB',
								'SO',
								'SB',
								'CS',
								'HBP',
								'SF',
								'AVG',
								'OBP',
								'SLG',
								'OPS',
							].map((key) => (
								<th
									key={key}
									className='p-2 border-b cursor-pointer'
									onClick={() => requestSort(key)}
								>
									{key.toUpperCase()} <span className={getClassNamesFor(key)}></span>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{sortedData.map((stats, index) => (
							<tr key={index}>
								<td className='p-2 border-b text-center'>{stats.player}</td>
								<td className='p-2 border-b text-center'>{stats.G}</td>
								<td className='p-2 border-b text-center'>{stats.AB}</td>
								<td className='p-2 border-b text-center'>{stats.R}</td>
								<td className='p-2 border-b text-center'>{stats.H}</td>
								<td className='p-2 border-b text-center'>{stats.RBI}</td>
								<td className='p-2 border-b text-center'>{stats.BB}</td>
								<td className='p-2 border-b text-center'>{stats.SO}</td>
								<td className='p-2 border-b text-center'>{stats.SB}</td>
								<td className='p-2 border-b text-center'>{stats.CS}</td>
								<td className='p-2 border-b text-center'>{stats.HBP}</td>
								<td className='p-2 border-b text-center'>{stats.SF}</td>
								<td className='p-2 border-b text-center'>{stats.AVG}</td>
								<td className='p-2 border-b text-center'>{stats.OBP}</td>
								<td className='p-2 border-b text-center'>{stats.SLG}</td>
								<td className='p-2 border-b text-center'>{stats.OPS}</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};

export default CustomGamesStats;
