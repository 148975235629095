import { Link, useLocation } from 'react-router-dom';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export default function Navbar() {
	const location = useLocation();
	const tabs = [
		{ name: 'Team Stats', href: '/', current: location.pathname === '/' },
		{ name: 'Game Stats', href: '/game', current: location.pathname === '/game' },
		{ name: 'Player Stats', href: '/players', current: location.pathname === '/players' },
		{ name: 'Player Comparison', href: '/compare', current: location.pathname === '/compare' },
	];

	return (
		<div>
			<div className='max-w-screen overflow-scroll'>
				<div className='border-b border-gray-200'>
					<nav
						aria-label='Tabs'
						className='-mb-px flex space-x-8'
					>
						{tabs.map((tab) => (
							<Link
								key={tab.name}
								to={tab.href}
								aria-current={tab.current ? 'page' : undefined}
								className={classNames(
									tab.current
										? 'border-indigo-700 text-indigo-700'
										: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
									'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium'
								)}
							>
								{tab.name}
							</Link>
						))}
					</nav>
				</div>
			</div>
		</div>
	);
}
