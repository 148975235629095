const aggregateStats = (data) => {
	const individualTotals = {};
	const teamTotals = {
		W: 0,
		L: 0,
		AB: 0,
		R: 0,
		H: 0,
		RBI: 0,
		BB: 0,
		SO: 0,
		SB: 0,
		CS: 0,
		TB: 0,
		HBP: 0,
		SF: 0,
		G: 0,
	};

	data.forEach((game) => {
		const playersInGame = new Set();

		game.data.forEach((player) => {
			const playerName = player.LINEUP.split(' #')[0];
			if (!playerName) return;

			const stats = {
				G: 0,
				AB: parseInt(player['AB']) || 0,
				R: parseInt(player['R']) || 0,
				H: parseInt(player['H']) || 0,
				RBI: parseInt(player['RBI']) || 0,
				BB: parseInt(player['BB']) || 0,
				SO: parseInt(player['SO']) || 0,
				SB: parseInt(player['SB']) || 0,
				CS: parseInt(player['CS']) || 0,
				TB: parseInt(player['TB']) || 0,
				HBP: parseInt(player['HBP']) || 0,
				SF: parseInt(player['SF']) || 0,
			};

			if (!individualTotals[playerName]) {
				individualTotals[playerName] = {
					G: 0,
					AB: 0,
					R: 0,
					H: 0,
					RBI: 0,
					BB: 0,
					SO: 0,
					SB: 0,
					CS: 0,
					TB: 0,
					HBP: 0,
					SF: 0,
					'#': player.JERSEY,
					name: playerName,
				};
			}

			Object.keys(stats).forEach((key) => {
				individualTotals[playerName][key] += stats[key];
				teamTotals[key] += stats[key];
			});
			stats.G += 1;
			playersInGame.add(playerName);
		});

		teamTotals.G += 1;

		playersInGame.forEach((playerName) => {
			individualTotals[playerName].G += 1;
		});
	});

	const calculateBA = (H, AB) => (AB > 0 ? (H / AB).toFixed(3) : '0.000');
	const calculateOBP = (H, BB, HBP, AB, SF) =>
		AB + BB + HBP + SF > 0 ? ((H + BB + HBP) / (AB + BB + HBP + SF)).toFixed(3) : '0.000';
	const calculateSLG = (TB, AB) => (AB > 0 ? (TB / AB).toFixed(3) : '0.000');
	const calculateOPS = (OBP, SLG) => (parseFloat(OBP) + parseFloat(SLG)).toFixed(3);

	Object.keys(individualTotals).forEach((playerName) => {
		const player = individualTotals[playerName];
		player.AVG = calculateBA(player.H, player.AB);
		player.OBP = calculateOBP(player.H, player.BB, player.HBP, player.AB, player.SF);
		player.SLG = calculateSLG(player.TB, player.AB);
		player.OPS = calculateOPS(player.OBP, player.SLG);
	});

	teamTotals.AVG = calculateBA(teamTotals.H, teamTotals.AB);
	teamTotals.OBP = calculateOBP(
		teamTotals.H,
		teamTotals.BB,
		teamTotals.HBP,
		teamTotals.AB,
		teamTotals.SF
	);
	teamTotals.SLG = calculateSLG(teamTotals.TB, teamTotals.AB);
	teamTotals.OPS = calculateOPS(teamTotals.OBP, teamTotals.SLG);

	return { individualTotals, teamTotals };
};

export default aggregateStats;
