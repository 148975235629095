import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './Layout';
import PlayerStats from './components/PlayerStats';
import StatsTable from './components/StatsTable';
import TeamStats from './components/TeamStats';
import PlayerComparison from './components/PlayerComparison';
import GameStats from './components/GameStats';
import RecentGamesStats from './components/RecentGameStats';
import CustomGamesStats from './components/CustomGameStats';
export default function Router() {
	const routes = [
		{
			path: '',
			element: <Layout />,
			children: [
				{
					path: '',
					element: (
						<div>
							<TeamStats />
							<StatsTable />
						</div>
					),
				},
				{
					path: 'players',
					element: (
						<div>
							<PlayerStats />
							<StatsTable />
						</div>
					),
				},
				{
					path: 'compare',
					element: <PlayerComparison />,
				},
				{
					path: 'game',
					element: (
						<div>
							<GameStats />
							<CustomGamesStats />
							<RecentGamesStats />
						</div>
					),
				},
			],
		},
	];
	const router = createBrowserRouter(routes);
	return (
		<div>
			<RouterProvider router={router} />
		</div>
	);
}
