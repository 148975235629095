import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import LOGO from './assets/logo.png';
import Navbar from './components/Navbar';
export default function Layout() {
	return (
		<div className='p-4 max-w-screen'>
			<div className='flex space-x-4 justify-between'>
				<Link to='/'>
					<div>
						<h1 className='text-3xl font-bold mb-4'>Trash Pandas Stats</h1>
						<h2 className='text-xl font-bold mb-4'>5-10 (.333) - 6th Place</h2>
					</div>
				</Link>
				<Link to='/'>
					<img
						src={LOGO}
						className='object-fit h-24 w-24 mb-4'
					/>
				</Link>
			</div>
			<Navbar />
			<Outlet />
		</div>
	);
}
