import React, { useState, useEffect } from 'react';
import { useStats } from '../context/StatsContext';

const PlayerStats = ({}) => {
	const { selectedPlayer, setSelectedPlayer, individualTotals, setIndividualTotals } = useStats();
	const playerName = selectedPlayer;
	const stats = individualTotals[selectedPlayer] || {};
	return (
		<div className='max-w-screen overflow-scroll player-stats bg-white p-6 rounded-lg shadow-lg mb-6'>
			<h2 className='inline mr-4 mb-4 text-2xl font-semibold'>View by Player</h2>
			<select
				className='p-2 border w-44 rounded border-gray-300'
				onChange={(e) => setSelectedPlayer(e.target.value)}
				value={selectedPlayer || ''}
			>
				<option value=''>Select Player</option>
				{Object.keys(individualTotals).map((player) => (
					<option
						key={player}
						value={player}
					>
						{player}
					</option>
				))}
			</select>
			{selectedPlayer && (
				<div>
					<h3 className='mt-3 text-xl font-semibold mb-2'>
						{playerName} #{stats['#']}
					</h3>
					<table className='whitespace-nowrap  w-full bg-white'>
						<thead className='bg-gray-100 tracking-wider'>
							<tr>
								<th className='p-2 border-b'>G</th>
								<th className='p-2 border-b'>AB</th>
								<th className='p-2 border-b'>R</th>
								<th className='p-2 border-b'>H</th>
								<th className='p-2 border-b'>RBI</th>
								<th className='p-2 border-b'>BB</th>
								<th className='p-2 border-b'>SO</th>
								<th className='p-2 border-b'>SB</th>
								<th className='p-2 border-b'>CS</th>
								<th className='p-2 border-b'>HBP</th>
								<th className='p-2 border-b'>SF</th>
								<th className='p-2 border-b'>AVG</th>
								<th className='p-2 border-b'>OBP</th>
								<th className='p-2 border-b'>SLG</th>
								<th className='p-2 border-b'>OPS</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className='p-2 border-b text-center'>{stats.G}</td>
								<td className='p-2 border-b text-center'>{stats.AB}</td>
								<td className='p-2 border-b text-center'>{stats.R}</td>
								<td className='p-2 border-b text-center'>{stats.H}</td>
								<td className='p-2 border-b text-center'>{stats.RBI}</td>
								<td className='p-2 border-b text-center'>{stats.BB}</td>
								<td className='p-2 border-b text-center'>{stats.SO}</td>
								<td className='p-2 border-b text-center'>{stats.SB}</td>
								<td className='p-2 border-b text-center'>{stats.CS}</td>
								<td className='p-2 border-b text-center'>{stats.HBP}</td>
								<td className='p-2 border-b text-center'>{stats.SF}</td>
								<td className='p-2 border-b text-center'>{stats.AVG}</td>
								<td className='p-2 border-b text-center'>{stats.OBP}</td>
								<td className='p-2 border-b text-center'>{stats.SLG}</td>
								<td className='p-2 border-b text-center'>{stats.OPS}</td>
							</tr>
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

export default PlayerStats;
