const games = [
	{
		event_id: 'f14a6ac3-b4dc-4fd4-8e4e-5ad3c3f37545',
		game_stream: {
			id: 'dbb63518-1afb-42bd-b39c-c0f263c2f736',
			game_id: 'f14a6ac3-b4dc-4fd4-8e4e-5ad3c3f37545',
			game_status: 'completed',
			home_away: 'away',
			is_archived: false,
			opponent_id: 'c7a71821-ba73-4517-b30a-435c4ff28640',
			sabertooth_major_version: 4,
			scoring_user_id: 'bf623ba3-6b09-4a10-b18d-b2ffe6b3c6b7',
		},
		last_scoring_update: '2024-05-17T02:32:03.214Z',
		opponent_team_score: 9,
		owning_team_score: 18,
		home_away: 'away',
		game_status: 'completed',
		sport_specific: {
			bats: {
				total_outs: 33,
				inning_details: {
					inning: 6,
					half: 'bottom',
				},
			},
		},
	},
	{
		event_id: '3c473bbc-209c-4ebe-a5d4-015066c1fb39',
		game_stream: {
			id: '67591b39-7944-4885-ad83-2229a6c3c6f4',
			game_id: '3c473bbc-209c-4ebe-a5d4-015066c1fb39',
			game_status: 'completed',
			home_away: 'home',
			is_archived: false,
			opponent_id: 'bd4362ff-7e25-4cd0-af66-78a2e0ab76d8',
			sabertooth_major_version: 4,
			scoring_user_id: 'bf623ba3-6b09-4a10-b18d-b2ffe6b3c6b7',
		},
		last_scoring_update: '2024-05-22T02:54:24.592Z',
		opponent_team_score: 10,
		owning_team_score: 2,
		home_away: 'home',
		game_status: 'completed',
		sport_specific: {
			bats: {
				total_outs: 54,
				inning_details: {
					inning: 10,
					half: 'top',
				},
			},
		},
	},
	{
		event_id: 'e4d99f2e-281b-4d8e-96fe-53ff5cbfaea9',
		game_stream: {
			id: '733bb8a0-caca-49f5-ba9a-afdc00405b59',
			game_id: 'e4d99f2e-281b-4d8e-96fe-53ff5cbfaea9',
			game_status: 'completed',
			home_away: 'home',
			is_archived: false,
			opponent_id: '853bca44-feb8-425b-9f8e-1114e06cbc07',
			sabertooth_major_version: 4,
			scoring_user_id: 'bf623ba3-6b09-4a10-b18d-b2ffe6b3c6b7',
		},
		last_scoring_update: '2024-06-17T20:58:21.243Z',
		opponent_team_score: 13,
		owning_team_score: 6,
		home_away: 'home',
		game_status: 'completed',
		sport_specific: {
			bats: {
				total_outs: 42,
				inning_details: {
					inning: 8,
					half: 'top',
				},
			},
		},
	},
	{
		event_id: 'e1e6959c-9648-4c86-855d-a5deca36986c',
		game_stream: {
			id: '1b0c45d4-c5e4-47bc-9ac8-d0522dfb314a',
			game_id: 'e1e6959c-9648-4c86-855d-a5deca36986c',
			game_status: 'completed',
			home_away: 'away',
			is_archived: false,
			opponent_id: 'bc91328d-0f4b-4ead-9ab8-90467fad3fcf',
			sabertooth_major_version: 4,
			scoring_user_id: 'bf623ba3-6b09-4a10-b18d-b2ffe6b3c6b7',
		},
		last_scoring_update: '2024-06-21T02:57:25.477Z',
		opponent_team_score: 8,
		owning_team_score: 7,
		home_away: 'away',
		game_status: 'completed',
		sport_specific: {
			bats: {
				total_outs: 47,
				inning_details: {
					inning: 8,
					half: 'bottom',
				},
			},
		},
	},
	{
		event_id: '00583cde-a404-4af6-93a6-0a5464892031',
		game_stream: {
			id: '3ec8f628-8110-42ee-bfd0-3948309b2c90',
			game_id: '00583cde-a404-4af6-93a6-0a5464892031',
			game_status: 'completed',
			home_away: 'away',
			is_archived: false,
			opponent_id: 'bd4362ff-7e25-4cd0-af66-78a2e0ab76d8',
			sabertooth_major_version: 4,
			scoring_user_id: 'bf623ba3-6b09-4a10-b18d-b2ffe6b3c6b7',
		},
		last_scoring_update: '2024-06-23T20:45:30.527Z',
		opponent_team_score: 8,
		owning_team_score: 7,
		home_away: 'away',
		game_status: 'completed',
		sport_specific: {
			bats: {
				total_outs: 53,
				inning_details: {
					inning: 9,
					half: 'bottom',
				},
			},
		},
	},
	{
		event_id: '63d48de8-011a-49bc-bff6-30efdd778daa',
		game_stream: {
			id: '90107f20-0f78-429f-80ea-eab28789a5df',
			game_id: '63d48de8-011a-49bc-bff6-30efdd778daa',
			game_status: 'completed',
			home_away: 'home',
			is_archived: false,
			opponent_id: '56ae793e-3b50-48b1-be73-ab22effae71d',
			sabertooth_major_version: 4,
			scoring_user_id: 'bf623ba3-6b09-4a10-b18d-b2ffe6b3c6b7',
		},
		last_scoring_update: '2024-06-26T03:10:05.450Z',
		opponent_team_score: 4,
		owning_team_score: 8,
		home_away: 'home',
		game_status: 'completed',
		sport_specific: {
			bats: {
				total_outs: 39,
				inning_details: {
					inning: 7,
					half: 'bottom',
				},
			},
		},
	},
	{
		event_id: '6a5a447f-6cad-4384-8995-ca8e83e0d98a',
		game_stream: {
			id: 'a4088f2a-30e1-4548-b16f-835b29341a67',
			game_id: '6a5a447f-6cad-4384-8995-ca8e83e0d98a',
			game_status: 'completed',
			home_away: 'away',
			is_archived: false,
			opponent_id: '2c0cfb4e-d1da-4505-a318-943c2e902ee6',
			sabertooth_major_version: 4,
			scoring_user_id: 'bf623ba3-6b09-4a10-b18d-b2ffe6b3c6b7',
		},
		last_scoring_update: '2024-06-29T17:30:04.923Z',
		opponent_team_score: 9,
		owning_team_score: 8,
		home_away: 'away',
		game_status: 'completed',
		sport_specific: {
			bats: {
				total_outs: 39,
				inning_details: {
					inning: 7,
					half: 'bottom',
				},
			},
		},
	},
	{
		event_id: '7517a5a5-d43b-4cd9-b043-546fc1cef761',
		game_stream: {
			id: '99c45e42-46c5-4f04-afa6-c7b6132bb4f8',
			game_id: '7517a5a5-d43b-4cd9-b043-546fc1cef761',
			game_status: 'completed',
			home_away: 'home',
			is_archived: false,
			opponent_id: 'de185640-fe84-49de-8897-132871f92cff',
			sabertooth_major_version: 4,
			scoring_user_id: 'bf623ba3-6b09-4a10-b18d-b2ffe6b3c6b7',
		},
		last_scoring_update: '2024-07-12T02:40:19.046Z',
		opponent_team_score: 7,
		owning_team_score: 9,
		home_away: 'home',
		game_status: 'completed',
		sport_specific: {
			bats: {
				total_outs: 39,
				inning_details: {
					inning: 7,
					half: 'bottom',
				},
			},
		},
	},
	{
		event_id: '3be10b5d-f87e-489a-9bd8-bf60aecfea3b',
		game_stream: {
			id: 'fb4417b4-eef8-4b70-8e89-74946fd677cd',
			game_id: '3be10b5d-f87e-489a-9bd8-bf60aecfea3b',
			game_status: 'completed',
			home_away: 'away',
			is_archived: false,
			opponent_id: '853bca44-feb8-425b-9f8e-1114e06cbc07',
			sabertooth_major_version: 4,
			scoring_user_id: 'bf623ba3-6b09-4a10-b18d-b2ffe6b3c6b7',
		},
		last_scoring_update: '2024-07-13T20:39:27.766Z',
		opponent_team_score: 8,
		owning_team_score: 2,
		home_away: 'away',
		game_status: 'completed',
		sport_specific: {
			bats: {
				total_outs: 45,
				inning_details: {
					inning: 8,
					half: 'bottom',
				},
			},
		},
	},
	{
		event_id: 'd5776aad-5398-4f03-8ce7-3d4d755aa4cf',
		game_stream: {
			id: '4d93aad2-d396-4b0c-9456-a3f3df0c696c',
			game_id: 'd5776aad-5398-4f03-8ce7-3d4d755aa4cf',
			game_status: 'completed',
			home_away: 'away',
			is_archived: false,
			opponent_id: '1a821eeb-d92b-46da-9e85-77b285354055',
			sabertooth_major_version: 4,
			scoring_user_id: 'bf623ba3-6b09-4a10-b18d-b2ffe6b3c6b7',
		},
		last_scoring_update: '2024-07-19T02:39:57.933Z',
		opponent_team_score: 11,
		owning_team_score: 14,
		home_away: 'away',
		game_status: 'completed',
		sport_specific: {
			bats: {
				total_outs: 36,
				inning_details: {
					inning: 7,
					half: 'top',
				},
			},
		},
	},
	{
		event_id: 'a41c19f8-1d7f-4159-aac7-b0bcaf47be6e',
		game_stream: {
			id: '81a5ad65-faff-4880-a8dd-61597d080d07',
			game_id: 'a41c19f8-1d7f-4159-aac7-b0bcaf47be6e',
			game_status: 'completed',
			home_away: 'home',
			is_archived: false,
			opponent_id: 'bc91328d-0f4b-4ead-9ab8-90467fad3fcf',
			sabertooth_major_version: 4,
			scoring_user_id: 'bf623ba3-6b09-4a10-b18d-b2ffe6b3c6b7',
		},
		last_scoring_update: '2024-07-20T17:29:34.134Z',
		opponent_team_score: 6,
		owning_team_score: 4,
		home_away: 'home',
		game_status: 'completed',
		sport_specific: {
			bats: {
				total_outs: 54,
				inning_details: {
					inning: 10,
					half: 'top',
				},
			},
		},
	},
	{
		event_id: '733cc3c2-50ef-497c-adac-e26f384b2b91',
		game_stream: {
			id: '86502784-28f8-4044-8550-5423d0c21198',
			game_id: '733cc3c2-50ef-497c-adac-e26f384b2b91',
			game_status: 'completed',
			home_away: 'home',
			is_archived: false,
			opponent_id: 'bd4362ff-7e25-4cd0-af66-78a2e0ab76d8',
			sabertooth_major_version: 4,
			scoring_user_id: 'bf623ba3-6b09-4a10-b18d-b2ffe6b3c6b7',
		},
		last_scoring_update: '2024-07-24T02:46:47.988Z',
		opponent_team_score: 16,
		owning_team_score: 12,
		home_away: 'home',
		game_status: 'completed',
		sport_specific: {
			bats: {
				total_outs: 30,
				inning_details: {
					inning: 6,
					half: 'top',
				},
			},
		},
	},
	{
		event_id: '3c92e8be-9a8d-4f96-a365-3bf6fd946639',
		game_stream: {
			id: '059a521d-ac07-485f-8354-d5cc8266db7f',
			game_id: '3c92e8be-9a8d-4f96-a365-3bf6fd946639',
			game_status: 'completed',
			home_away: 'home',
			is_archived: false,
			opponent_id: 'de185640-fe84-49de-8897-132871f92cff',
			sabertooth_major_version: 4,
			scoring_user_id: 'bf623ba3-6b09-4a10-b18d-b2ffe6b3c6b7',
		},
		last_scoring_update: '2024-05-12T14:02:03.057Z',
		opponent_team_score: 5,
		owning_team_score: 9,
		home_away: 'home',
		game_status: 'completed',
		sport_specific: {
			bats: {
				total_outs: 38,
				inning_details: {
					inning: 7,
					half: 'top',
				},
			},
		},
	},
	{
		event_id: '662534f6-952e-4e8d-827d-f36228f15b1b',
		game_stream: {
			id: '8ba1e8f4-ef78-426b-ac67-eda38f253e49',
			game_id: '662534f6-952e-4e8d-827d-f36228f15b1b',
			game_status: 'completed',
			home_away: 'away',
			is_archived: false,
			opponent_id: '853bca44-feb8-425b-9f8e-1114e06cbc07',
			sabertooth_major_version: 4,
			scoring_user_id: 'bf623ba3-6b09-4a10-b18d-b2ffe6b3c6b7',
		},
		last_scoring_update: '2024-06-15T02:41:24.481Z',
		opponent_team_score: 12,
		owning_team_score: 2,
		home_away: 'away',
		game_status: 'completed',
		sport_specific: {
			bats: {
				total_outs: 41,
				inning_details: {
					inning: 7,
					half: 'bottom',
				},
			},
		},
	},
	{
		event_id: '77ce3cc5-442f-4ea1-8888-9f481acc3314',
		game_stream: {
			id: 'ce44cc2a-2db1-46b0-bb62-f63aa5904f8b',
			game_id: '77ce3cc5-442f-4ea1-8888-9f481acc3314',
			game_status: 'completed',
			home_away: 'away',
			is_archived: false,
			opponent_id: '56ae793e-3b50-48b1-be73-ab22effae71d',
			sabertooth_major_version: 4,
			scoring_user_id: 'bf623ba3-6b09-4a10-b18d-b2ffe6b3c6b7',
		},
		last_scoring_update: '2024-06-14T18:07:30.584Z',
		opponent_team_score: 7,
		owning_team_score: 2,
		home_away: 'away',
		game_status: 'completed',
		sport_specific: {
			bats: {
				total_outs: 33,
				inning_details: {
					inning: 6,
					half: 'bottom',
				},
			},
		},
	},
];

function gameLookup(date) {
	const dateFormatted = new Date(date).toISOString().split('T')[0];
	const game = games.find((game) => {
		console.log({ gst: game.last_scoring_update.split('T')[0], dateFormatted });
		return game.last_scoring_update.split('T')[0] === dateFormatted;
	});
	return game;
}

export default gameLookup;
