import React from 'react';
import { useStats } from '../context/StatsContext';

const PlayerComparison = ({}) => {
	const { setPlayer1, player1, setPlayer2, player2, individualTotals } = useStats();
	return (
		<div className='player-comparison bg-white p-6 rounded-lg shadow-lg mb-6'>
			<h3 className='text-xl font-semibold mb-4'>Player Comparison</h3>
			<div className='flex space-x-4'>
				<select
					className='p-2 border w-48 rounded border-gray-300'
					onChange={(e) => setPlayer1(e.target.value)}
					value={player1 || ''}
				>
					<option value=''>Select Player 1</option>
					{Object.keys(individualTotals).map((player) => (
						<option
							key={player}
							value={player}
						>
							{player}
						</option>
					))}
				</select>
				<div className=''>
					<select
						className='p-2 border w-48 rounded border-gray-300'
						onChange={(e) => setPlayer2(e.target.value)}
						value={player2 || ''}
					>
						<option
							value=''
							disabled
						>
							Select Player 2
						</option>
						{Object.keys(individualTotals).map((player) => (
							<option
								key={player}
								value={player}
							>
								{player}
							</option>
						))}
					</select>
				</div>
			</div>
			{player1 && player2 && (
				<table className='whitespace-nowrap min-w-full bg-white'>
					<thead className='bg-gray-100'>
						<tr>
							<th className='p-2 border-b'>Stat</th>
							<th className='p-2 border-b'>
								{individualTotals[player1].name} - #{individualTotals[player1]['#']}
							</th>
							<th className='p-2 border-b'>
								{individualTotals[player2].name} - #{individualTotals[player2]['#']}
							</th>
						</tr>
					</thead>
					<tbody>
						{Object.keys(individualTotals[player1]).map(
							(stat) =>
								stat !== 'games' && (
									<tr key={stat}>
										<td className='p-2 border-b text-center font-semibold'>{stat.toUpperCase()}</td>
										<td className='p-2 border-b text-center'>{individualTotals[player1][stat]}</td>
										<td className='p-2 border-b text-center'>{individualTotals[player2][stat]}</td>
									</tr>
								)
						)}
					</tbody>
				</table>
			)}
		</div>
	);
};

export default PlayerComparison;
